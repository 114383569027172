import React from 'react';
import {Link} from "react-router-dom";

//Assets
import logo from '../../assets/images/alumo-logo.svg';
import AILink from "../abstract/link";
import AIIMage from "../abstract/Image";


function AIFooter() {
    return (

        <footer className={'ai-main-footer'}>
            <div className={"container"}>
                <div className={'row g-0 ai-footer-top-row'}>
                    <div className={"col-md-2 ai-footer-brand"}>
                        <Link to={"/"}>
                            <AIIMage imgSrc={logo} imgAlt={'Alumo Logo'} className={'ai-img-100'}/>
                        </Link>
                    </div>
                    <div className={"col-md-6 col-lg-5 offset-lg-2"}>
                        <div className={'row g-0'}>
                            <div className="col-md-6">
                                <div className="row contact-detail-row">
                                    <a href={"tel:+27871330033"}>
                                        <div className={'ai-contact-icon'}>
                                            <i className="ri-phone-line"></i>
                                        </div>
                                    </a>
                                    <span className={'ai-contact-text'}>
                                        Call us
                                        <span className={'ai-contact-link'}><a href={"tel:+27871330033"}>+27 87 133 0033</a> </span>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row g-0 contact-detail-row">
                                    <a href={"mailto:sales@alumo.co.za"}>
                                        <div className={'ai-contact-icon'}>
                                            <i className="ri-mail-open-line"></i>
                                        </div>
                                    </a>
                                    <span className={'ai-contact-text'}>
                                        Email us
                                        <span className={'ai-contact-link'}><a href={"mailto:sales@alumo.co.za"}>sales@alumo.co.za</a> </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-lg-2 col-md-2'}>
                        <div className={'row g-0 justify-content-end ai-footer-social-icon-row'}>
                            <a  href={'https://www.facebook.com/alumoenergy'} target={'_blank'} rel="noreferrer">
                                <div className={'ai-footer-social-icon'}>
                                    <i className="ri-facebook-fill"></i>
                                </div>
                            </a>
                            <a href={'https://www.linkedin.com/company/alumo-energy/'} className={'ai-ml-1'} target={'_blank'} rel="noreferrer">
                                <div className={'ai-footer-social-icon'}>
                                    <i className="ri-linkedin-fill"></i>
                                </div>
                            </a>
                            <a href={'https://www.instagram.com/alumo_energy/'} className={'ai-ml-1'} target={'_blank'} rel="noreferrer">
                                <div className={'ai-footer-social-icon'}>
                                    <i className="ri-instagram-line"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={'ai-footer-bottom-row'}>
                    <div className={"col-md-3 ai-copyright-text"}>
                        <p>©Copyright Alumo Energy. All rights reserved. Website by <a target='_blank' rel="noreferrer" href={"https://aglet.co.za"}>Aglet</a>.</p>
                    </div>
                    <div className={"col-md-9 ai-footer-navigation"}>
                        <div className={'row g-0'}>
                            {<AILink text={'Email Disclaimer'} url={'/disclaimer'}/>}
                            {<AILink text={'Careers'} url={'/careers'}/>}
                            {<AILink text={'Sizing tool'} url={'/solar-calculator-south-africa'}/>}
                            {<AILink text={'Instant quote'} url={'/instant-quote-home-solar-power'}/>}
                            {/*{<AILink text={'Payment Plans'} url={'/solar-payment-options'}/>}*/}
                            {<AILink text={'Solutions'} url={'/solar-system-for-house'}/>}
                            {<AILink text={'Articles'} url={'/articles'}/>}
                            {<AILink text={'Terms and Conditions'} url={'/terms-and-conditions'}/>}
                            {<AILink text={'Privacy Policy'} url={'/privacy-policy'}/>}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default AIFooter;
