import React, {useEffect, useRef, useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import {useLocation} from "react-router-dom";
// import Lottie from 'react-lottie'
// import Lottie from 'lottie-react-web'
import { Player, Controls } from '@lottiefiles/react-lottie-player';

// import {ReactComponent as ButtonIcon} from '../../assets/images/alumo-btn-icon.svg'
//
import ButtonIcon from '../../assets/animations/alumo-button-icon.json';

//Regular Expression
const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;

function AIButton(props) {
    const location = useLocation()


    const buttonRef = useRef(null)

    /**
     *
     */
    function handleMouseEnter (){
        if(!buttonRef.current){return}
        buttonRef.current.play()
    }

    /**
     *
     */
    function handleMouseLeave(){
        if(!buttonRef.current){return}
        buttonRef.current.stop()
    }
    // console.log(props)
    return (
        <>
            {
                props.isBtn ?
                    <button className='ai-button' onClick={props.handleClick} onMouseEnter={()=>handleMouseEnter()}
                            onMouseLeave={()=>handleMouseLeave()}>
                        {props.children}
                        {props.type !== 'no-lottie' &&
                        <span className={'ai-btn-icon-lottie'}>
                            <Player
                                src={ButtonIcon}
                                autoplay={false}
                                loop={false}
                                ref={buttonRef}
                                keepLastFrame={true}
                                direction={1}
                            >
                            </Player>
                        </span>
                        }
                    </button>
                    :props.type === 'outside-link' ?
                        <a target={'_blank'}
                           href={props.btnData.replace(aiButtonHref, '$1')}
                           state={{prevRoute: location.pathname}}
                           className={`ai-button ${props.className ? props.className : ''} ${props.btnIcon && 'has-ri-icon'}`}
                           onMouseEnter={()=>handleMouseEnter()}
                           onMouseLeave={()=>handleMouseLeave()}
                        >
                            {props.btnIcon && <props.btnIcon/>}
                            {props.btnData.replace(aiButtonHref, '$2')}
                        </a>

                        :
                        !props.btnData ?
                            <Link
                                to={{pathname:props.url, state: {test: 'test'}}}
                                className={`ai-button ${props.className ? props.className : ''}`}
                                state={{prevRoute: location.pathname}}
                                // onMouseEnter={()=>{setPlayAnimation(true)}}
                                // onMouseLeave={()=>{setPlayAnimation(false)}}
                                onMouseEnter={()=>handleMouseEnter()}
                                onMouseLeave={()=>handleMouseLeave()}
                            >
                                {props.children}
                                {
                                    props.type === 'ai-saving-btn' &&
                                    // <span className={'ai-btn-icon'}>
                                    //     <ButtonIcon/>
                                    // </span>
                                    <span className={'ai-btn-icon-lottie'}>
                                <Player
                                    src={ButtonIcon}
                                    autoplay={false}
                                    loop={false}
                                    ref={buttonRef}
                                    keepLastFrame={true}
                                    direction={1}
                                >
                                </Player>
                            </span>
                                }
                            </Link>

                            :

                            props.type === 'customer-experiences' ?
                                <Link
                                    target={props.target}
                                    to={{pathname: props.btnData.replace(aiButtonHref, '$1')}}
                                    state={{prevRoute: location.pathname, selectedCat: props.category ? props.category : ''}}
                                    className={`ai-button ${props.className ? props.className : ''}`}
                                    // onMouseEnter={()=>{setPlayAnimation(true)}}
                                    onMouseEnter={()=>handleMouseEnter()}
                                    onMouseLeave={()=>handleMouseLeave()}
                                >
                                    {props.btnData.replace(aiButtonHref, '$2')}
                                    {
                                        props.type === 'ai-saving-btn' &&
                                        // <span className={'ai-btn-icon'}>
                                        //      <ButtonIcon/>
                                        // </span>
                                        <span className={'ai-btn-icon-lottie'}>
                                <Player
                                    src={ButtonIcon}
                                    autoplay={false}
                                    loop={false}
                                    ref={buttonRef}
                                    keepLastFrame={true}
                                    direction={1}
                                >
                                </Player>
                            </span>
                                    }
                                </Link>
                                :

                                <Link
                                    target={props.target}
                                    to={{pathname: props.btnData.replace(aiButtonHref, '$1')}}
                                    state={{prevRoute: location.pathname}}
                                    className={`ai-button ${props.className ? props.className : ''}`}
                                    // onMouseEnter={()=>{setPlayAnimation(true)}}
                                    onMouseEnter={()=>handleMouseEnter()}
                                    onMouseLeave={()=>handleMouseLeave()}
                                >
                                    {props.btnData.replace(aiButtonHref, '$2')}
                                    {
                                        props.type === 'ai-saving-btn' &&
                                        // <span className={'ai-btn-icon'}>
                                        //      <ButtonIcon/>
                                        // </span>
                                        <span className={'ai-btn-icon-lottie'}>
                                <Player
                                    src={ButtonIcon}
                                    autoplay={false}
                                    loop={false}
                                    ref={buttonRef}
                                    keepLastFrame={true}
                                    direction={1}
                                >
                                </Player>
                            </span>
                                    }
                            </Link>
            }

        </>
    )
}

export default AIButton;